import { AuthenticationResult } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import * as React from "react";
import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from "reactstrap";
import { getAccessToken, getAppScope, getRoles, getVenues, updateAccessToken, updateRoles, updateVenues } from "../Redux/techHubSlice";
import { VenueConfig } from "../Types";
const AppHomePage = React.lazy(() =>
  import("./AppHomePage").then(({ AppHomePage }) => ({ default: AppHomePage }))
);
const SearchPage = React.lazy(() =>
  import("./StoriesAdmin/SearchHeader").then(({ SearchHeader }) => ({
    default: SearchHeader,
  }))
);
const PAMInternational = React.lazy(() =>
  import("./PamCreation/TournamentAdmin").then(({ TournamentAdmin }) => ({
    default: TournamentAdmin,
  }))
);
const TechHubLogging = React.lazy(() =>
  import("./TechHubLogs/ReportingHome").then(({ ReportingHome }) => ({
    default: ReportingHome,
  }))
);

export const RouterComponent: React.FC = () => {

  const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const appScope = useSelector(getAppScope);
    const dispatcher = useDispatch();
    
    React.useEffect(() => {
        setTimeout(refreshToken, 300);
    }, [account]);

    async function refreshToken() {
        if (account) {
            instance.acquireTokenSilent({
                scopes: [appScope],
                account: account
            }).then((response: AuthenticationResult) => {
                dispatcher(updateAccessToken(response.accessToken));
                const roles: string[] = response.account.idTokenClaims["roles"];
                dispatcher(updateRoles(roles));
                getVenueList(response.accessToken);
            });
        }
    }

    async function getVenueList(accessToken: string) {
      var client = new XMLHttpRequest();
      client.onreadystatechange = function () {
        if (client.readyState === client.DONE && client.status === 200) {
          const foundVenues: VenueConfig[] = JSON.parse(this.responseText);
          dispatcher(updateVenues(foundVenues));
        }
      };
      client.open("GET", "api/Configuration/GetVenues", true);
      client.setRequestHeader("Authorization", "Bearer " + accessToken);
      client.send();
    }

  return (
    <Container>
      <Router>
        <Suspense fallback={<div>Loading</div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/storyadmin" component={StoryAdmin} />
            <Route path="/createtournament" component={TournamentCreation} />
            <Route path="/logging" component={LoggingPage} />           
          </Switch>
        </Suspense>
      </Router>
    </Container>
  );
};

const Home: React.FC = () => {
  return (
    <div>
      <AppHomePage />
    </div>
  );
};

const StoryAdmin: React.FC = () => {
  return (
    <div>
      <SearchPage key="SearchHeader" />
    </div>
  );
};

const TournamentCreation: React.FC = () => {
  return (
    <div>
      <PAMInternational key="TournamentCreation" />
    </div>
  );
};

const LoggingPage: React.FC = () => {
  return (
    <div>
      <TechHubLogging />
    </div>
  );
};
